import { Profile } from './../Reports/reports.model';
import { catchError } from 'rxjs/operators';
import { ReportQuestionsAnswersCountFilter } from './../Exam/exams/exams.model';
import { Exam } from './../Exam/exam/exam.model';
import { ExamClasses, ExamCourses, ExamAssignStudents } from './../Exam/exam-assign/exam-assign.model';
import { Subscription, Curriculum, Grade, Constant, SubSubject } from '../../pages/Setups/setups.model';
import { Guid } from 'guid-typescript';
import { environment } from './../../../environments/environment';
import { MainCurriculum, Service, Subject, AcademicYear, MainSubject, AcademicYearGrade, GradeSubject, Student, PagedGradeStudent, Semester, Class, Staff, PagedStaff, ClassesSubSubjects, UsersRoles, SubGrade, Course, SubjectPrerequisite, PagedStudent } from './setups.model';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Report, ReportFilter } from '../Reports/reports.model';

@Injectable({
  providedIn: 'root'
})
export class SetupsService {

  constructor(public http: HttpClient) { }

  getConstant(): Observable<Constant[]> {
    return this.http.get<Constant[]>(`${environment.apiUrl}/Constant`);//.pipe(catchError(this.handelError));
  }

  getConstantGroup(mId: string, langId: string): Observable<Constant[]> {
    return this.http.get<Constant[]>(`${environment.apiUrl}/Constant/GetGroup/${mId}?langId=${langId}`);//.pipe(catchError(this.handelError));
  }

  getConstantsMainGroups(mId: string, langId: string): Observable<Constant[]> {
    return this.http.get<Constant[]>(`${environment.apiUrl}/Constant/GetConstantsMainGroups/${mId}?langId=${langId}`);//.pipe(catchError(this.handelError));
  }

  getConstantById(id: string): Observable<Constant>//Observable<constant[]>
  {
    return this.http.get<Constant>(`${environment.apiUrl}/Constant/${id}`).pipe(catchError(this.handelError));
  }

  updateConstant(Evaluation: Constant): Observable<Constant> {
    return this.http.put<Constant>(`${environment.apiUrl}/Constant/${Evaluation.id}`, Evaluation, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });//.pipe(catchError(this.handelError));
  }

  addConstant(newRecord: Constant) {
    return this.http.post<Constant>(`${environment.apiUrl}/Constant`, newRecord, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(catchError(this.handelError));
  }

  deletConstant(Evaluation: Constant) {
    return this.http.delete<Constant>(`${environment.apiUrl}/Constant/${Evaluation.id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(catchError(this.handelError));
  }

  getLanguageConstants(mId: string): Observable<Constant[]> {
    return this.http.get<Constant[]>(`${environment.apiUrl}/Constant/GetLanguageConstants/${mId}`);
  }

  // getCurriculums(): Observable<Curriculum[]> {
  //   return this.http.get<Curriculum[]>(`${environment.apiUrl}/Service/Curriculum`);
  // }

  // getGradesByUserIdAndCurriculumId(curriculumId : Guid): Observable<Grade[]> {
  //   return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetGradesByUserIdAndCurriculumId/${curriculumId}`);
  // }

  // getGradesByCurriculumId(curriculumId : Guid): Observable<Grade[]> {
  //   return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetGradesByCurriculumId/${curriculumId}`);
  // }

  getSubGradesByGradeId(gradeId: Guid): Observable<SubGrade[]> {
    return this.http.get<SubGrade[]>(`${environment.apiUrl}/Grade/GetSubGradesByGradeId/${gradeId}`);
  }

  getSubjectsByUserIdAndSubGradeId(subGradeId: Guid): Observable<Subject[]> {
    return this.http.get<Subject[]>(`${environment.apiUrl}/Subject/GetSubjectsByUserIdAndSubGradeId/${subGradeId}`);
  }

  getSubSubjectsByUserIdAndSubGradeId(subGradeId: Guid): Observable<Subject[]> {
    return this.http.get<Subject[]>(`${environment.apiUrl}/Subject/GetSubSubjectsByUserIdAndSubGradeId/${subGradeId}`);
  }

  getSubSubjectsByUserIdAndGradeId(gradeId: Guid): Observable<SubSubject[]> {
    return this.http.get<SubSubject[]>(`${environment.apiUrl}/Subject/GetSubSubjectsByUserIdAndGradeId/${gradeId}`);
  }

  getSubjectsBySubGradeId(subGradeId: Guid): Observable<Subject[]> {
    return this.http.get<Subject[]>(`${environment.apiUrl}/Subject/GetSubjectsBySubGradeId/${subGradeId}`);
  }

  getSubSubjectsBySubjectId(subjectId: Guid): Observable<SubSubject[]> {
    return this.http.get<SubSubject[]>(`${environment.apiUrl}/Subject/GetSubSubjectsBySubjectId/${subjectId}`);
  }
  // getLevels(): Observable<Level[]> {
  //   return this.http.get<Level[]>(`${environment.apiUrl}/Constant/GetSubscriptionGroup/15?langId=01-01`);
  // }

  getSubscriptionGroup(mId: string, LangId: string): Observable<Constant[]> {
    return this.http.get<Constant[]>(`${environment.apiUrl}/Constant/GetSubscriptionGroup/${mId}?langId=${LangId}`);
  }


  // getCategories(): Observable<Category[]> {
  //   return this.http.get<Category[]>(`${environment.apiUrl}/Constant/GetSubscriptionGroup/14?langId=01-01`);
  // }

  private handelError(errorResponse: HttpErrorResponse) {
    console.error(
      `Backend returned code ${errorResponse.error}, ` +
      `body was: ${errorResponse.error}`);
    return throwError('There is a problem with the service' + errorResponse.status);
  }

  getTeachersByClassSubSubjectId(subSubjectId: Guid, gradeClassId: Guid): Observable<Staff[]> {
    return this.http.get<Staff[]>(`${environment.apiUrl}/Profile/GetTeachersByClassSubSubjectId/${subSubjectId}/${gradeClassId}`);
  }

  getSubSubjectsByClass(Ids: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Subject/GetSubSubjectsByClass`, Ids);
  }

  getSubSubjectsByStudent(Ids: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Subject/GetSubSubjectsByStudent`, Ids);
  }

  getCurriculums(): Observable<Curriculum[]> {
    return this.http.get<Curriculum[]>(`${environment.apiUrl}/Service/Curriculum`);
  }

  getGradesByUserIdAndCurriculumId(curriculumId: Guid): Observable<Grade[]> {
    return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetGradesByUserIdAndCurriculumId/${curriculumId}`);
  }

  getGradesByCurriculumId(curriculumId: Guid): Observable<Grade[]> {
    return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetGradesByCurriculumId/${curriculumId}`);
  }

  getSubGradesByCurriculumId(curriculumId: Guid): Observable<Grade[]> {
    return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetSubGradesByCurriculumId/${curriculumId}`);
  }

  getClasses(examId: Guid, isPublicExamAssign: boolean): Observable<ExamClasses[]> {
    return this.http.get<ExamClasses[]>(`${environment.apiUrl}/AssignExam/GetClasses/${examId}/${isPublicExamAssign}`);
  }

  getServerDateTime(): Observable<Date> {
    return this.http.get<Date>(`${environment.apiUrl}/PrivateExam/GetServerDateTime`);
  }

  getSubscriptionLogo(ServiceId: Guid): Observable<Subscription> {
    return this.http.get<Subscription>(`${environment.apiUrl}/Profile/GetSubscriptionLogo/${localStorage.currentUserSubscription}`);
  }

  getServices(): Observable<Service[]> {
    return this.http.get<Service[]>(`${environment.apiUrl}/Service`);
  }

  getServicesBySubscriptionId(): Observable<Service[]> {
    return this.http.get<Service[]>(`${environment.apiUrl}/Service/BySubscription/${localStorage.currentUserSubscription}`);
  }

  getUserServices(): Observable<Service[]> {
    return this.http.get<Service[]>(`${environment.apiUrl}/Service/GetUserServices`);
  }

  getAllCurriculumsByServiceId(ServiceId: Guid): Observable<Curriculum[]> {
    return this.http.get<Curriculum[]>(`${environment.apiUrl}/Service/GetAllCurriculumsByServiceId/${ServiceId}`);
  }

  getMainCurriculums(): Observable<MainCurriculum[]> {
    return this.http.get<MainCurriculum[]>(`${environment.apiUrl}/MainCurriculum`);
  }

  getMainGradesByAcademicYear(academicYearId: Guid): Observable<AcademicYearGrade[]> {
    return this.http.get<AcademicYearGrade[]>(`${environment.apiUrl}/MainGrade/GetMainGradesByAcademicYear/${academicYearId}`);
  }

  getAllByCurriculumAndServiceId(curriculumId: Guid, serviceId: Guid): Observable<Grade[]> {
    return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetAllByCurriculumAndServiceId/${curriculumId}/${serviceId}`);
  }

  getMainSubjects(MainCurriculumId: Guid): Observable<MainSubject[]> {
    return this.http.get<MainSubject[]>(`${environment.apiUrl}/MainSubject/GetAllByCurriculumId/${MainCurriculumId}`);
  }

  getSelectedSubjectsByGradeId(gradeId: Guid): Observable<GradeSubject[]> {
    return this.http.get<GradeSubject[]>(`${environment.apiUrl}/Subject/GetSelectedSubjectsByGradeId/${gradeId}`);
  }

  getClassesBySubGradeId(subGradeId: Guid): Observable<Class[]> {
    return this.http.get<Class[]>(`${environment.apiUrl}/Class/GetClassesBySubGradeId/${subGradeId}`);
  }

  getAddedSubjectsByGradeId(gradeId: Guid): Observable<GradeSubject[]> {
    return this.http.get<GradeSubject[]>(`${environment.apiUrl}/Subject/GetAddedSubjectsByGradeId/${gradeId}`);
  }

  // getAddedSubjectsBySubGradeId(subGradeId: Guid): Observable<GradeSubject[]> {
  //   return this.http.get<GradeSubject[]>(`${environment.apiUrl}/Subject/GetAddedSubjectsBySubGradeId/${subGradeId}`);
  // }

  getAddedSubSubjectsBySubjectId(subjectId: Guid): Observable<GradeSubject[]> {
    return this.http.get<GradeSubject[]>(`${environment.apiUrl}/Subject/GetAddedSubSubjectsBySubjectId/${subjectId}`);
  }

  getAllAcademicYears(serviceId: Guid, curriculumId: Guid): Observable<AcademicYear[]> {
    return this.http.get<AcademicYear[]>(`${environment.apiUrl}/AcadimicYear/GetAllAcademicYears/${serviceId}/${curriculumId}`);
  }

  addNewCurriculum(curriculum: Curriculum): Observable<Curriculum> {
    return this.http.post<Curriculum>(`${environment.apiUrl}/Service/AddNewCurriculum`, curriculum);
  }

  updateCurriculum(curriculum: Curriculum): Observable<Curriculum> {
    return this.http.put<Curriculum>(`${environment.apiUrl}/Service/Curriculum/${curriculum.id}`, curriculum);
  }

  addNewService(service: Service): Observable<Service> {
    return this.http.post<Service>(`${environment.apiUrl}/Service`, service);
  }

  updateService(service: Service): Observable<Service> {
    return this.http.put<Service>(`${environment.apiUrl}/Service/${service.id}`, service);
  }

  addNewAcademicYear(academicYear: AcademicYear): Observable<AcademicYear> {
    return this.http.post<AcademicYear>(`${environment.apiUrl}/AcadimicYear`, academicYear);
  }

  updateAcademicYear(academicYear: AcademicYear): Observable<AcademicYear> {
    return this.http.put<AcademicYear>(`${environment.apiUrl}/AcadimicYear/${academicYear.id}`, academicYear);
  }

  getStudentsBySubGradeId(subGradeId: Guid, pageNo: number, pageSize: number): Observable<PagedGradeStudent> {
    return this.http.get<PagedGradeStudent>(`${environment.apiUrl}/Profile/GetStudentsBySubGradeId/${subGradeId}?pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  getStudentsByClassId(classId: Guid): Observable<Student[]> {
    return this.http.get<Student[]>(`${environment.apiUrl}/Profile/GetStudentsByClassId/${classId}`);
  }

  addStudentProfileToSubGrade(subGradeId: Guid, student: Student): Observable<Student> {
    return this.http.post<Student>(`${environment.apiUrl}/Profile/AddStudentProfileToSubGrade/${subGradeId}`, student);
  }

  addStudentProfileToClass(subGradeId: Guid, classId: Guid, student: Student): Observable<Student> {
    return this.http.post<Student>(`${environment.apiUrl}/Profile/AddStudentProfileToClass/${subGradeId}/${classId}`, student);
  }

  getProfileById(profileId: Guid): Observable<Student[]> {
    return this.http.get<Student[]>(`${environment.apiUrl}/Profile/${profileId}`);
  }

  updateProfile(profile: Profile): Observable<Student> {
    return this.http.put<Student>(`${environment.apiUrl}/Profile/${profile.id}`, profile);
  }

  deleteStudentProfile(studentId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Profile/${studentId}`);
  }

  changeStudentSubGrade(studentId: Guid, subGradeId: Guid): Observable<Student> {
    return this.http.put<Student>(`${environment.apiUrl}/Profile/ChangeStudentSubGrade/${studentId}/${subGradeId}`, {});
  }

  addGradesToCurriculum(selectedGrades): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Grade/AddGradesToCurriculum`, selectedGrades);
  }

  addSubjectToGrade(addedSubject, subGradeId: Guid): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Subject/AddSubjectToGrade/${subGradeId}`, addedSubject);
  }

  addSubjectToGrades(addedSubjects): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Subject/AddSubjectToGrades`, addedSubjects);
  }
  getSemestersByAcademicYear(academicYearId: Guid): Observable<Semester[]> {
    return this.http.get<Semester[]>(`${environment.apiUrl}/Semester/GetAllByAcademicYear/${academicYearId}`);
  }

  addNewSemester(semester: Semester): Observable<Semester> {
    return this.http.post<Semester>(`${environment.apiUrl}/Semester`, semester);
  }

  updateSemester(semester: Semester, semesterId: Guid): Observable<Semester> {
    semester.id = semesterId;
    return this.http.put<Semester>(`${environment.apiUrl}/Semester/${semesterId}`, semester);
  }

  activateSemester(semesterId: Guid, academicYearId: Guid): Observable<Semester> {
    return this.http.put<Semester>(`${environment.apiUrl}/Semester/ActivateSemester/${semesterId}/${academicYearId}`, {});
  }

  getGradesByAcademicYearId(academicYearId: Guid): Observable<Grade[]> {
    return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetGradesByAcademicYearId/${academicYearId}`);
  }

  getClassesBySubGradeIdAndSemesterId(subGradeId: Guid, semesterId: Guid): Observable<Class[]> {
    return this.http.get<Class[]>(`${environment.apiUrl}/Class/GetClassesBySubGradeIdAndSemesterId/${subGradeId}/${semesterId}`);
  }

  updateClaasesInformation(classes): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Class/UpdateClaasesInformation`, classes);
  }

  generateClasses(gradeId: Guid, semesterId: Guid, numberOfClasses: number): Observable<Class[]> {
    return this.http.post<Class[]>(`${environment.apiUrl}/Class/GenerateClasses/${gradeId}/${semesterId}/${numberOfClasses}`, {});
  }

  addNewClass(gradeClass: Class): Observable<Semester> {
    return this.http.post<Semester>(`${environment.apiUrl}/Class`, gradeClass);
  }

  updateNumberOfClasses(numberOfClasses: number, gradeId: Guid): Observable<Semester> {
    return this.http.put<Semester>(`${environment.apiUrl}/Grade/UpdateNumberOfClasses/${numberOfClasses}/${gradeId}`, {});
  }

  getUnassignedStudentsToClassBySubGradeId(serviceId: Guid, gradeId: Guid): Observable<Student[]> {
    return this.http.get<Student[]>(`${environment.apiUrl}/Profile/GetUnassignedStudentsToClassBySubGradeId/${serviceId}/${gradeId}`);
  }

  getAssignedStudentsToClassByClassId(classId: Guid): Observable<Student[]> {
    return this.http.get<Student[]>(`${environment.apiUrl}/Profile/GetAssignedStudentsToClassByClassId/${classId}`);
  }

  assignStudentToClass(studentId: Guid, classId: Guid): Observable<Student[]> {
    return this.http.post<Student[]>(`${environment.apiUrl}/Profile/AssignStudentToClass/${studentId}/${classId}`, {});
  }

  removeStudentFromClass(studentId: Guid, classId: Guid): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.apiUrl}/Profile/RemoveStudentFromClass/${studentId}/${classId}`, {});
  }

  getStaffBySubscriptionId(pageNo: number, pageSize: number): Observable<PagedStaff> {
    return this.http.get<PagedStaff>(`${environment.apiUrl}/Profile/GetStaffBySubscriptionId/${localStorage.currentUserSubscription}?pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  createStaffProfile(staff: Staff): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/Profile/AddStaffProfile`, staff);
  }

  updateStaffProfile(Staff: Staff): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/Profile/UpdateStaffProfile/${Staff.id}`, Staff);
  }

  getStaffProfileById(staffId: Guid): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/Profile/GetStaffProfileById/${staffId}`);
  }

  updateSatffProfile(staff: Staff): Observable<Staff> {
    return this.http.put<Staff>(`${environment.apiUrl}/Profile/${staff.id}`, staff);
  }

  deleteProfile(staffId: Guid): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.apiUrl}/Profile/${staffId}`, {});
  }

  deactivateProfile(userId: Guid): Observable<Staff> {
    return this.http.put<Staff>(`${environment.apiUrl}/Profile/DeactivateProfile/${userId}`, {});
  }

  activateProfile(userId: Guid): Observable<Staff> {
    return this.http.put<Staff>(`${environment.apiUrl}/Profile/ActivateProfile/${userId}`, {});
  }

  // getSubjectsBycurriculumIdAndGradeId(curriculumId: Guid, gradeId: Guid): Observable<Subject[]> {
  //   return this.http.get<Subject[]>(`${environment.apiUrl}/Subject/GetSubjectsBycurriculumIdAndGradeId/${curriculumId}/${gradeId}`);
  // }

  getClassesSubjectsBySubGradeId(subGradeId: Guid, semesterId: Guid, serviceId: Guid): Observable<ClassesSubSubjects[]> {
    return this.http.get<ClassesSubSubjects[]>(`${environment.apiUrl}/Class/GetClassesSubjectsBySubGradeId/${subGradeId}/${semesterId}/${serviceId}`);
  }

  getAllActiveStaffBySubscription(serviceId: Guid): Observable<Staff[]> {
    return this.http.get<Staff[]>(`${environment.apiUrl}/Profile/GetAllActiveStaffBySubscription/${localStorage.currentUserSubscription}/${serviceId}`);
  }

  createTeachingRelation(classId: Guid, subSubjectId: Guid, teacherUserId: Guid): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Profile/CreateTeachingRelation/${classId}/${subSubjectId}/${teacherUserId}`, {});
  }

  updateTeachingRelation(classId: Guid, subjectId: Guid, staffId: Guid): Observable<Staff> {
    return this.http.put<Staff>(`${environment.apiUrl}/Profile/UpdateTeachingRelation/${classId}/${subjectId}/${staffId}`, {});
  }

  getSupervisorsByAcademicYear(serviceId: Guid, academicYearId: Guid): Observable<Staff[]> {
    return this.http.get<Staff[]>(`${environment.apiUrl}/Profile/GetSupervisorsByAcademicYear/${serviceId}/${academicYearId}`);
  }

  getManagersByAcademicYear(serviceId: Guid, academicYearId: Guid): Observable<Staff[]> {
    return this.http.get<Staff[]>(`${environment.apiUrl}/Profile/GetManagersByAcademicYear/${serviceId}/${academicYearId}`);
  }

  getSupervisorRelations(serviceId: Guid, supervisorId: Guid): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/Profile/GetSupervisorRelations/${serviceId}/${supervisorId}`);
  }

  getManagerRelations(serviceId: Guid, managerId: Guid): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/Profile/GetManagerRelations/${serviceId}/${managerId}`);
  }
  deleteSupervisorRelation(relationId: Guid, supervisorId: Guid): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.apiUrl}/Profile/DeleteSupervisorRelation/${relationId}/${supervisorId}`, {});
  }

  deleteManagerRelation(relationId: Guid, managerId: Guid): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.apiUrl}/Profile/DeleteManagerRelation/${relationId}/${managerId}`, {});
  }

  createSupervisorRelation(relations: any): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/Profile/CreateSupervisorRelation`, relations);
  }

  createManagerRelation(relations: any): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/Profile/CreateManagerRelation`, relations);
  }

  changePassword(changePassword: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Account/ChangePassword`, changePassword);
  }

  resetUserPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/Account/ResetUserPassword/${email}`, {});
  }

  getExamsByServiceId(serviceId: Guid): Observable<Exam[]> {
    return this.http.get<Exam[]>(`${environment.apiUrl}/PrivateExam/GetExamsByServiceId/${serviceId}`);
  }

  getUsersRoles(): Observable<UsersRoles[]> {
    return this.http.get<UsersRoles[]>(`${environment.apiUrl}/Profile/GetUsersRoles`);
  }

  // getExamsByFilters(serviceId: Guid, gradeId: Guid, roundId: string, skillId: string): Observable<Exam[]> {
  //   return this.http.get<Exam[]>(`${environment.apiUrl}/PrivateExam/GetExamsByFilters/${serviceId}/${gradeId}/${roundId}/${skillId}`);
  // }

  getExamsByFilters(filter: ReportQuestionsAnswersCountFilter): Observable<any[]> {
    let httpParams = new HttpParams();
    Object.keys(filter).forEach(function (key) {
      httpParams = httpParams.append(key, filter[key]);
    });
    return this.http.get<any[]>(`${environment.apiUrl}/PrivateExam/GetExamsByFilters?${httpParams.toString()}`);
  }

  getReportsSetup(): Observable<Report[]> {
    return this.http.get<Report[]>(`${environment.apiUrl}/Report`);
  }

  saveReportSetup(report: Report): Observable<Report> {
    return this.http.post<Report>(`${environment.apiUrl}/Report`, report);
  }

  updateReportSetup(report: Report): Observable<Report> {
    return this.http.put<Report>(`${environment.apiUrl}/Report/${report.id}`, report);
  }

  getReportSetupByIndexTreeNodeId(reportId: Guid): Observable<Report> {
    return this.http.get<Report>(`${environment.apiUrl}/Report/GetReportSetupByIndexTreeNodeId/${reportId}`);
  }

  getReportFiltersByReportId(reportId: Guid): Observable<ReportFilter[]> {
    return this.http.get<ReportFilter[]>(`${environment.apiUrl}/Report/GetReportFiltersByReportId/${reportId}`);
  }

  saveReportFilter(reportFilter: ReportFilter): Observable<ReportFilter> {
    return this.http.post<ReportFilter>(`${environment.apiUrl}/Report/AddReportFilter`, reportFilter);
  }

  updateReportFilter(reportFilter: ReportFilter): Observable<ReportFilter> {
    return this.http.put<ReportFilter>(`${environment.apiUrl}/Report/UpdateReportFilter/${reportFilter.id}`, reportFilter);
  }

  deleteReportFilter(reportId: Guid): Observable<ReportFilter> {
    return this.http.delete<ReportFilter>(`${environment.apiUrl}/Report/DeleteReportFilter/${reportId}`, {});
  }

  getSubjectsByCurriculumId(curriculumId: Guid): Observable<Subject[]> {
    return this.http.get<Subject[]>(`${environment.apiUrl}/Subject/GetSubjectsByCurriculumId/${curriculumId}`);
  }

  addCourse(course: Course): Observable<Course> {
    return this.http.post<Course>(`${environment.apiUrl}/Course/AddCourse`, course);
  }

  getCoursesBySemesterId(semesterId: Guid): Observable<Course[]> {
    return this.http.get<Course[]>(`${environment.apiUrl}/Course/GetCoursesBySemesterId/${semesterId}`);
  }

  getCourseById(courseId: Guid): Observable<Course> {
    return this.http.get<Course>(`${environment.apiUrl}/Course/GetCourseById/${courseId}`);
  }

  updateCourse(course: Course): Observable<Course> {
    return this.http.put<Course>(`${environment.apiUrl}/Course/UpdateCourse/${course.id}`, course);
  }

  deleteCourse(courseId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Course/${courseId}`);
  }

  getStudentsByServiceId(serviceId: Guid): Observable<Student[]> {
    return this.http.get<Student[]>(`${environment.apiUrl}/Profile/GetStudentsByServiceId/${serviceId}`);
  }

  addStudentToCourse(studentId: Guid, courseId: Guid): Observable<Student[]> {
    return this.http.post<Student[]>(`${environment.apiUrl}/Course/AddStudentToCourse/${studentId}/${courseId}`, {});
  }

  getUnassignedStudentsToCourseByServiceId(serviceId: Guid, courseId: Guid): Observable<Student[]> {
    return this.http.get<Student[]>(`${environment.apiUrl}/Course/GetUnassignedStudentsToCourseByServiceId/${serviceId}/${courseId}`);
  }

  getAssignedStudentsToCourse(courseId: Guid): Observable<Student[]> {
    return this.http.get<Student[]>(`${environment.apiUrl}/Course/GetAssignedStudentsToCourse/${courseId}`);
  }

  removeStudentFromCourse(studentId: Guid, courseId: Guid): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/Course/RemoveStudentFromCourse/${studentId}/${courseId}`, {});
  }

  deleteSubject(subjectId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Subject/${subjectId}`);
  }

  updateSubject(subject: Subject, subjectId: Guid): Observable<Subject> {
    subject.id = subjectId;
    return this.http.put<Subject>(`${environment.apiUrl}/Subject/${subjectId}`, subject);
  }

  updateGrade(grade: Grade, gradeId: Guid): Observable<Grade> {
    grade.id = gradeId;
    return this.http.put<Grade>(`${environment.apiUrl}/Grade/${gradeId}`, grade);
  }

  deleteGrade(gradeId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Grade/${gradeId}`);
  }

  getSubjectPrerequisitesBySubjectId(subjectId: Guid): Observable<SubjectPrerequisite[]> {
    return this.http.get<SubjectPrerequisite[]>(`${environment.apiUrl}/Subject/GetSubjectPrerequisitesBySubjectId/${subjectId}`);
  }

  addSubjectPrerequisite(subjectPrerequisite: SubjectPrerequisite): Observable<SubjectPrerequisite> {
    return this.http.post<SubjectPrerequisite>(`${environment.apiUrl}/Subject/AddSubjectPrerequisite`, subjectPrerequisite);
  }

  updateSubjectPrerequisite(subjectPrerequisite: SubjectPrerequisite): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/Subject/UpdateSubjectPrerequisite/${subjectPrerequisite.id}`, subjectPrerequisite);
  }

  deleteSubjectPrerequisite(subjectId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Subject/DeleteSubjectPrerequisite/${subjectId}`);
  }

  addSubGrade(subGrade: SubGrade): Observable<SubGrade> {
    return this.http.post<SubGrade>(`${environment.apiUrl}/Grade/AddSubGrade`, subGrade);
  }

  updateSubGrade(subGrade: SubGrade): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/Grade/UpdateSubGrade/${subGrade.id}`, subGrade);
  }

  deleteSubGrade(subGradeId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Grade/DeleteSubGrade/${subGradeId}`);
  }

  addSubSubject(subSubject: SubSubject): Observable<SubSubject> {
    return this.http.post<SubSubject>(`${environment.apiUrl}/Subject/AddSubSubject`, subSubject);
  }

  updateSubSubject(subSubject: SubSubject): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/Subject/UpdateSubSubject/${subSubject.id}`, subSubject);
  }

  deleteSubSubject(subGradeId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Subject/DeleteSubSubject/${subGradeId}`);
  }

  getCoursesBySubjectId(subjectId: Guid): Observable<ExamCourses[]> {
    return this.http.get<ExamCourses[]>(`${environment.apiUrl}/Course/GetCoursesBySubjectId/${subjectId}`);
  }

  getCourseStudentsBySubjectId(subjectId: Guid): Observable<ExamAssignStudents[]> {
    return this.http.get<ExamAssignStudents[]>(`${environment.apiUrl}/Course/GetCourseStudentsBySubjectId/${subjectId}`);
  }

  getGradesBySubjectTeacher(): Observable<Grade[]> {
    return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetGradesBySubjectTeacher`);
  }

  getRoles(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/Account/Roles`);
  }

  addUserRole(username: string, role: string, serviceId: Guid): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Account/AddUserRole/${username}/${role}/${serviceId}`, {});
  }

  getUserRolesByUserId(userId: Guid): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/Account/GetUserRolesByUserId/${userId}`);
  }

  deleteUserRole(userId: Guid, roleId: Guid, serviceId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Account/DeleteUserRole/${userId}/${roleId}/${serviceId}`);
  }

  getStudentsBySubscriptionId(pageNo: number, pageSize: number): Observable<PagedStudent> {
    return this.http.get<PagedStudent>(`${environment.apiUrl}/Profile/GetStudentsBySubscriptionId/${localStorage.currentUserSubscription}?pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  getStudentProfileById(studentId: Guid): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/Profile/GetStudentProfileById/${studentId}`);
  }

  updateStudentProfile(student: Student): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/Profile/UpdateStudentProfile/${student.id}`, student);
  }

  getSubSubjectsBySubGradeId(subGradeId: Guid): Observable<SubSubject[]> {
    return this.http.get<SubSubject[]>(`${environment.apiUrl}/Subject/GetSubSubjectsBySubGradeId/${subGradeId}`);
  }
}
